import React from "react";

const BlurRotate = () => {

    return (
        <div className="blur-rotate">
        </div>
    );
}

export default BlurRotate;